import React from 'react';
import styled from 'styled-components';
import ContentfulRichText from 'components/RichText/ContentfulRichText';
import WebsiteImage from 'components/WebsiteImage';
import BackgroundImage from 'components/Core/BackgroundImage';
import { getEntry } from 'components/Contentful/ContentfulLayout/utils/entriesHelpers';

import quoteIcon from 'images/quote-icon-1.svg';

const Bender = ({ entries = [] }) => {
  const quote = getEntry('quote', entries);
  const name = getEntry('name', entries);
  const photo = getEntry('author', entries);
  const positionTitle = getEntry('author-title', entries);
  const featuredImage = getEntry('featured-image', entries);
  const styles = entries.filter((entry) => entry.templateId === 'section')?.[0]?.styles;
  const customCss = styles?.customStyling?.customStyling;

  return (
    <Container className="bg-white p-0 w-full" customCss={customCss}>
      <div className="relative flex max-w-[1336px] my-0 mx-auto px-[28px] pt-[70px] pb-[155px] sm-max:px-[20px] sm-max:pt-[20px] sm-max:pb-[80px] md-max:px-[20px] md-max:pt-[40px] md-max:pb-[120px]">
        <div className="quote-container relative bg-titan-blue-4 flex-[7_1_0%]">
          <div
            className={`prose prose-headings:m-0 prose-p:m-0 prose-headings:text-white prose-p:text-white py-[50px] px-[70px] sm-max:p-[30px] ${
              !featuredImage ? 'max-w-full' : ''
            }`}
          >
            <img className="w-[40px] h-[40px] m-0" src={quoteIcon} alt="quote-icon" />
            <ContentfulRichText
              className={`prose prose-headings:text-desktop-h2 md-max:prose-headings:text-mobile-h1 sm-max:prose-headings:text-mobile-h3 mt-[15px] ${
                !featuredImage ? 'max-w-full' : ''
              }`}
              richText={quote}
            />
            <div className="flex items-center justify-start gap-y-[0px] gap-x-[20px] mt-[30px]">
              <div className="w-[50px] h-[50px] prose prose-img:!w-[50px] prose-img:!h-[50px]">
                <WebsiteImage {...photo} />
              </div>
              <div>
                <ContentfulRichText
                  className="prose-p:text-desktop-h5 prose-p:font-extrabold"
                  richText={name}
                />
                <ContentfulRichText
                  className="prose-p:text-desktop-h6 prose-p:font-normal"
                  richText={positionTitle}
                />
              </div>
            </div>
          </div>
        </div>
        {featuredImage && (
          <div className="relative flex-[5_1_0%] shrink basis-0 md-max:hidden">
            <BackgroundImage image={featuredImage} loading="eager" />
          </div>
        )}
      </div>
    </Container>
  );
};

export default Bender;

const Container = styled.div`
  ${({ customCss }) => customCss};
`;
